import React from "react"
import { graphql } from "gatsby"
import { injectIntl } from "gatsby-plugin-react-intl"
import { getImage } from "gatsby-plugin-image"
import Layout from "../../components/Layout"
import Seo from "../../components/Seo"
import HeaderColor from "../../components/HeaderColor"
import HeaderCover from "../../components/HeaderCover"
import Footer from "../../components/Footer"
import { Link } from "gatsby-plugin-react-intl"

import { Row, Col } from "antd"
import styled from "@emotion/styled"

import { Collapse } from 'antd';

const { Panel } = Collapse;

function callback(key) {
    console.log(key);
  }

/* Top Design */
const FullwidthContainerheader = styled.div`
  margin-bottom: 60px;
  font-family: "Kanit", sans-serif;
  .gb-container {
    padding-right: 15px;
  }
  p {
    font-weight: 400;
    font-size: 16px;
    color: #7497B2;
    margin-bottom: 0.5em;
  }
  .title {
    font-weight: 400;
    font-size: 28px;
    color: #181818;
    margin-bottom: 0.1em;
    padding: 0 30px;
  }
  .content {
    font-weight: 400;
    font-size: 16px;
    color: #646464;
    p {
      font-weight: 400;
      font-size: 16px;
      color: #646464;
      padding: 0px 15px;
    }
  }

  @media only screen and (min-width: 576px) {
    .gb-container {
      padding-right: 10%;
    }
  }
  @media only screen and (min-width: 992px) {
    margin-bottom: 80px;
    .gb-container {
      padding-right: 15%;
    }
    p {
      font-size: 18px;
    }
    .title {
      font-size: 38px;
      margin-bottom: 0.5em;
      padding: 0 0px;
    }
    .content {
      p {
        padding: 0px 18%;
        font-size: 22px;
      }
    }
  }
`

const SitebarColumnsContainer = styled.div`
  margin: 30px 0;
  .item {
    font-family: "Kanit", sans-serif;
    .title {
      font-weight: 500;
      font-size: 24px;
      color: #2B3940;
      margin-top: 40px;
      margin-bottom: 10px;
      text-align: left;
    }
    .ant-collapse-header {
      font-size: 18px;
      color: rgba(100, 100, 100, 1)!important;
    }
    .anticon-right {
      margin-right: 25px!important;
      margin-left: 10px!important;
    }
    .ant-collapse-content-box {
      font-size: 14px;
      color: rgba(100, 100, 100, 1)!important;
      p {
        padding: 10px 10px;
        font-size:16px;
        line-height:1.8;
      }
    }
    .ant-collapse {
      background-color: rgba(250, 250, 250, 1)!important;
      border: 1px solid rgba(237, 237, 237, 1)!important;
    }
  }
  .itemsidebar {
    font-family: "Kanit", sans-serif;
    background: #FFFFFF;
    box-shadow: 0px 4px 46px rgba(139, 139, 139, 0.08);
    border-radius: 20px;
    padding: 30px 30px;
    .title {
      font-weight: 500;
      font-size: 28px;
      color: #181818;
      margin-bottom: 30px;
      text-align: center;
    }
    ul {
      list-style: none;
      padding-inline-start: 0px;
    }
    a {
      color: rgba(0, 0, 0, 0.85);
    }
    li {
      background: #FFFFFF;
      border: 1px solid #EDEDED;
      box-sizing: border-box;
      border-radius: 10px;
      padding: 20px 0px;
      padding-left: 30px;
      text-align: left;
      margin: 15px 0;
      font-size: 18px;
      a {
        color: rgba(0, 0, 0, 0.85);
      }
    }
    li:hover {
      box-shadow: 0px 4px 46px rgba(0, 0, 0, 0.2);
    }
  }

  @media only screen and (min-width: 768px) {
    margin: 30px 0;
    .item {
      font-family: "Kanit", sans-serif;
      .title {
        font-size: 24px;
      }
      .anticon-right {
        margin-right: 35px!important;
        margin-left: 25px!important;
      }
      .ant-collapse-header {
        font-size: 24px;
      }
      .ant-collapse-content-box {
        font-size: 20px;
        p {
          padding: 10px 40px;
          font-size: 18px;
          line-height:1.8;
        }
      }
    }
  }
`

const AntiCorruptionPolicyPage = ({ data, intl }) => {
  const locale = intl && intl.locale ? intl.locale : `th`
  const { pageFields } = data
  const {
    seoTitle,
    seoDescription,
    coverImage,
    objectives,
    scope,
    meanings,
    businessEthicsPolicy,
    businessEthicsManagementAndTreatment,
  } = pageFields.edges[0].node
  const getCoverImage = getImage(coverImage)


  return (
    <Layout>
      <Seo lang={locale} title={seoTitle} description={seoDescription} />
      <HeaderColor />
      <HeaderCover
        bgImage={getCoverImage}
        coverText={intl.formatMessage({ id: "subMenu24" })}
        secondLink="/corporate-governance/"
        secondText={intl.formatMessage({ id: "rootMenu2" })}
      />
      <FullwidthContainerheader style={{ marginTop: "100px", textAlign: "center", marginBottom: "20px" }}>
          <Row>
            <Col span={24}>
              <p>{intl.formatMessage({ id: "rootMenu2" })}</p>
              <h1 className="title">{intl.formatMessage({ id: "subMenu24" })}</h1>
            </Col>
          </Row>
      </FullwidthContainerheader>
      <SitebarColumnsContainer style={{ marginTop: "30px", marginBottom: "100px" }}>
        <div className="gb-container">
          <Row gutter={[30, 30]}>
            <Col xs={24} md={24} lg={18}>
              <div className="item">
                <Collapse accordion defaultActiveKey={['1']} onChange={callback}>
                    <Panel header={ "1. " + intl.formatMessage({ id: "subCoporate1" })} key="1">
                        <p>
                          <div
                            dangerouslySetInnerHTML={{
                            __html: objectives.objectives,
                            }}
                          />
                        </p>
                    </Panel>
                    <Panel header={ "2. " + intl.formatMessage({ id: "subCoporate2" })} key="2">
                        <p>
                          <div
                            dangerouslySetInnerHTML={{
                            __html: scope.scope,
                            }}
                          />
                        </p>
                    </Panel>
                    <Panel header={ "3. " + intl.formatMessage({ id: "subCoporate3" })} key="3">
                        <p>
                          <div
                            dangerouslySetInnerHTML={{
                            __html: meanings.meanings,
                            }}
                          />
                        </p>
                    </Panel>
                    <Panel header={ "4. " + intl.formatMessage({ id: "subCoporate4" })} key="4">
                        <p>
                          <div
                            dangerouslySetInnerHTML={{
                            __html: businessEthicsPolicy.businessEthicsPolicy,
                            }}
                          />
                        </p>
                    </Panel>
                    <Panel header={ "5. " + intl.formatMessage({ id: "subCoporate5" })} key="5">
                        <p>
                          <div
                            dangerouslySetInnerHTML={{
                            __html: businessEthicsManagementAndTreatment.businessEthicsManagementAndTreatment,
                            }}
                          />
                        </p>
                    </Panel>
                </Collapse>
              </div>
            </Col>
            <Col xs={24} md={24} lg={6}>
              <div className="itemsidebar">
                <h3 className="title">{intl.formatMessage({ id: "rootMenu2" })}</h3>
                <ul>
                  <Link to="/corporate-governance/business-ethics-policy/"><li>{intl.formatMessage({ id: "subMenu21" })}</li></Link>
                  <Link to="/corporate-governance/health-policy/"><li>{intl.formatMessage({ id: "subMenu28" })}</li></Link>
                  <Link to="/corporate-governance/environmental-policy/"><li>{intl.formatMessage({ id: "subMenu29" })}</li></Link>
                  <Link to="/corporate-governance/receive-a-complaint/"><li>{intl.formatMessage({ id: "subMenu23" })}</li></Link>
                  <Link to="/corporate-governance/esg-policy/"><li>{intl.formatMessage({ id: "subMenu30" })}</li></Link>
                </ul>
              </div>
            </Col>
          </Row>
        </div>
      </SitebarColumnsContainer>
      <Footer />
    </Layout>
  )
}

export const query = graphql`
  query AntiCorruptionPolicyPageQuery($locale: String) {
    pageFields: allContentfulAntiCorruptionCorruptionPage(
      filter: { node_locale: { eq: $locale } }
    ) {
      edges {
        node {
          seoTitle
          seoDescription
          coverImage {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          }
          objectives {
            objectives
          }
          scope {
            scope
          }
          meanings {
            meanings
          }
          businessEthicsPolicy {
            businessEthicsPolicy
          }
          businessEthicsManagementAndTreatment {
            businessEthicsManagementAndTreatment
          }
        }
      }
    }
  }
`

export default injectIntl(AntiCorruptionPolicyPage)